import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { AlertV2Plugin, vTooltip, vPrivacy } from 'sxope-design-system'
import VeeValidateConfig from '@/plugins/VeeValidate'

const app = createApp(App)

app.mixin({
  computed: {
    /* eslint-disable */
    $listeners() {
      const listeners = {}
      Object.keys(this.$attrs).forEach((key) => {
        if (key.startsWith('on')) {
          const eventName = key.replace('on', '').replace('On', '')
          listeners[eventName.charAt(0).toLowerCase() + eventName.slice(1)] = this.$attrs[key]
        }
      })
      return listeners
    }
  }
})

app.use(router)

app.use(VeeValidateConfig)
app.use(AlertV2Plugin)

app.directive('tooltip', vTooltip)
app.directive('privacy', vPrivacy)

app.mount('#app')
