import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/login/token',
      name: '2fa',
      component: () => import('@/views/phone/PhoneVerify.vue'),
      meta: {
        title: 'Please Confirm by SMS Token'
      }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        title: 'Register'
      }
    },
    {
      path: '/register/:token',
      name: 'invite-reg',
      component: () => import('@/views/RegisterByInvite.vue'),
      meta: {
        title: 'Register by Invite'
      }
    },
    {
      path: '/password/reset',
      name: 'password_reset',
      component: () => import('@/views/password/SendResetLink.vue'),
      meta: {
        title: 'Reset Your Password'
      }
    },
    {
      path: '/password/reset/:token',
      name: 'password_update',
      component: () => import('@/views/password/UpdatePassword.vue'),
      meta: {
        title: 'Enter a new password'
      }
    },
    {
      path: '/password/new/:token',
      name: 'password_new',
      component: () => import('@/views/password/UpdatePassword.vue'),
      meta: {
        title: 'Create Your Password'
      }
    },
    {
      path: '/password/expired',
      name: 'password_expired',
      component: () => import('@/views/password/PasswordExpired.vue'),
      meta: {
        title: 'Password Expired'
      }
    },
    {
      path: '/optin',
      name: 'optin',
      component: () => import('@/views/Optin.vue'),
      meta: {
        title: 'Privacy Statement'
      }
    },
    {
      path: '/request-access',
      name: 'request-access',
      component: () => import('@/views/RequestAccess.vue'),
      meta: {
        title: 'Open SXOPE Desktop App'
      }
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('@/views/Organizations.vue'),
      meta: {
        title: 'Select Organization'
      }
    },
    {
      path: '/locked',
      name: 'locked',
      component: () => import('@/views/errors/Locked.vue'),
      meta: {
        title: 'Locked'
      }
    },
    {
      path: '/phone/update/:token',
      name: 'phone-update',
      component: () => import('@/views/phone/PhoneUpdate.vue'),
      meta: {
        title: 'Add Phone Number'
      }
    },
    {
      path: '/phone/verify/:token',
      name: 'phone-verify',
      component: () => import('@/views/phone/PhoneVerify.vue'),
      meta: {
        title: 'Verify Phone Number'
      }
    },
    {
      path: '/sxope-apps',
      name: 'sxope-apps',
      component: () => import('@/views/SxopeApps.vue'),
      meta: {
        title: 'All Apps'
      }
    },
    {
      path: '/sxope-desktop',
      name: 'sxope-desktop',
      component: () => import('@/views/SxopeDesktop.vue'),
      meta: {
        title: 'Download Desktop Quality'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  next()

  document.querySelector('title').textContent = `SXOPE ID ${
    to.meta.title ? ` - ${to.meta.title}` : ''
  }`
})

export default router
