<template>
  <RouterView v-if="!blade_data.isHttpError" />

  <div v-else class="iam-body-wrapper auth-error">
    <TopBar collapsed :hide-menu-icon="true">
      <template #left>
        <a href="/home">
          <div class="app-logo"></div>
        </a>
      </template>
    </TopBar>

    <SxopeBody>
      <template #body>
        <ErrorPage
          :errorCode="blade_data.code"
          :pageTitle="
            errorCodes[blade_data.code]
              ? errorCodes[blade_data.code].title
              : blade_data.title
                ? blade_data.title
                : 'Internal Server Error'
          "
          :footerMessage="errorCodes[blade_data.code] ? errorCodes[blade_data.code].message : ''"
          backURL="/home"
          buttonSize="small"
          :showButton="blade_data.code !== 1000"
        />
      </template>
    </SxopeBody>
  </div>

  <AlertV2Outlet showProgress zIndex="9999999" />
</template>

<script setup>
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { AlertV2Outlet, TopBar, SxopeBody, ErrorPage } from 'sxope-design-system'

const fpId = ref(null)

provide('fingerprintId', fpId)
provide('sxAlert', getCurrentInstance()?.appContext.config.globalProperties.$sx.alert)

let blade_data = window.blade_data

if (blade_data) {
  provide('blade_data', blade_data)
  delete window.blade_data
} else {
  console.log('No blade data')
}

onMounted(async () => {
  const fp = await FingerprintJS.load()
  const result = await fp.get()

  fpId.value = result.visitorId

  if (blade_data.isCopilot) {
    zE(function () {
      zE.hide()
    })
  }
})

const errorCodes = {
  401: {
    title: 'Wrong Credentials',
    message: 'You don’t have permissions to view this section.'
  },
  403: {
    title: 'Access Denied',
    message: 'You don’t have permissions to view this section.'
  },
  404: {
    title: 'Page Not Found',
    message: 'We can’t find the page you are looking for.'
  },
  419: {
    title: 'Page Expired',
    message: 'We can’t find the page you are looking for.'
  },
  500: {
    title: 'Internal Server Error',
    message: `We are working on this...`
  },
  503: {
    title: 'Service Temporarily Unavailable',
    message: `We are working on this...`
  }
}
</script>

<style lang="scss">
@use 'sxope-theme-v2/all.css' as *;

// Reset
* {
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}
*,
::before,
::after {
  box-sizing: border-box;
}
body {
  font-family: SegoeUI, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--firefly);
}
a {
  text-decoration: none;
}
*,
:focus,
button:focus {
  outline: none;
}
.mb-16 {
  margin-bottom: 16px;
}
.link {
  font-weight: 400;
  text-decoration: none;
  line-height: 20px;
  font-size: 14px;
  color: var(--azure-blue);
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: var(--azure-blue-dark);
  }
  &:hover {
    text-decoration: underline;
  }
}
.app-logo {
  background-image: url(/images/logo-full-sxope-blue.svg);
  background-size: 101px 31px;
  height: 31px;
  width: 101px;
}
</style>
