import { configure, defineRule } from 'vee-validate'
import { required, max, regex } from '@vee-validate/rules'

defineRule('required', required)
defineRule('max', max)
defineRule('regex', regex)
defineRule('string', (value) => {
  if (!value || !value.length) return true
  if (!/^[a-zA-Z-' ]+$/.test(value)) return false

  return true
})
defineRule('name', (value) => {
  if (!value || !value.length) return true
  if (!/^[a-zA-Z][a-zA-Z\.\'\- ]*$/.test(value)) return false

  return true
})
defineRule('minmax', (value, [min, max]) => {
  if (!value || !value.length) return true

  return value.length >= min && value.length <= max
})
defineRule('email', (value) => {
  if (!value || !value.length) return true
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
      value
    )
  ) {
    return false
  }

  return true
})

// INFO: PhoneInput has own rules
const messages = {
  required: 'This field is required.',
  string: 'Invalid {field}',
  name: 'Invalid {field}',
  regex: 'Invalid {field}',
  email: 'Invalid email address',
  minmax: 'Line length must be between {param1} and {param2} characters.',
  max: 'Line length must not exceed {param1} characters.',
  exist: 'This {field_name} has already been taken.'
}

export default {
  install() {
    configure({
      generateMessage: (context) =>
        messages[context.rule.name]
          .replace('{field}', context.label.toLocaleLowerCase())
          .replace('{param1}', context.rule.params[0])
          .replace('{param2}', context.rule.params[1])
    })
  }
}
